import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify)

const vuetify = new Vuetify({
    options: {
        customProperties: true
      },
    theme: {
        themes: {
          light: {
            primary: '#592168',
            darkgrey: '#272727',
            success: '#27AE60',
            warning: '#F2994A',
            error: '#EB5757'
          },
        },
    },
    icons: {
        iconfont: 'mdi' || 'icons8', // default - only for display purposes
    },
})

export default vuetify
