<template>
  <v-container class="mx-0 pa-0">
    <!--upload-picker-->
    <v-card flat class="mt-3" width="100%" v-if="message === ''">
      <v-card-title class="text-subtitle-1 mt-2 font-weight-bold"
        >Upload a picture
        <v-spacer></v-spacer>
        <!--file-picker-trigger-->
        <!-- <v-btn class="mr-2" @click="openCamera()">
          Take picture
        </v-btn> -->
        <v-btn @click="click1" class="secondary white--text">upload file</v-btn>
        <!--/file-picker-trigger-->
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <!--file-picker-->
        <input
          type="file"
          ref="input1"
          style="display: none"
          @change="previewFile"
          accept="image/*;capture=camera"
        />
        <!--/file-picker-->

        <!--image-preview-->
        <div v-if="fileData != null">
          <v-img
            v-if="
              fileData.type === 'image/jpeg' ||
                fileData.type === 'image/png' ||
                fileData.type === 'image/jpg'
            "
            class="preview my-2 mx-auto"
            height="268"
            width="356"
            :src="fileURL"
          />
        </div>
        <!--/image-preview-->

        <!--file-caption-->
        <!-- <v-row class=" ma-4 text-center">
          <v-text-field v-model="caption" label="Write a caption for the photo">
          </v-text-field>
        </v-row> -->
        <!--/file-caption-->

        <v-row v-if="fileData != null" class="mx-3">
          <!--file-name-->
          <h4><strong>Selected file: </strong>{{ fileData.name }}</h4>
          <!--/file-name-->

          <v-spacer></v-spacer>
          <!--upload-button-->
          <v-btn
            class="text-right mr-2"
            color="red white--text"
            v-if="hideUploadButton === false"
            @click="uploadNewFile"
            >cancel</v-btn
          >
          <v-btn
            class="text-right"
            color="primary white--text"
            v-if="hideUploadButton === false"
            @click="create"
            >upload</v-btn
          >
          <!--/upload-button-->
        </v-row>
      </v-card-text>

      <v-card-actions class="mb-0 pb-0">
        <!--upload-progress-->
        <v-row
          class="px-1 py-0 mb-0"
          v-if="hideUploadButton === false && isUploading"
        >
          <v-progress-linear v-model="uploadValue" height="25">
            <strong>{{ Math.ceil(uploadValue) }}%</strong>
          </v-progress-linear>
        </v-row>
        <!--/upload-progress-->
      </v-card-actions>
    </v-card>
    <!--/upload-picker-->

    <!--upload-message-->
    <v-card class="mt-3" v-else>
      <v-card-title class="mx-4 py-4">Upload a file </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="text-center">
        <!--upload-message-->
        <v-icon :color="messageColor" size="100">{{ messageIcon }}</v-icon>
        <br />
        <h3>{{ message }}</h3>
        <br />
        <!--/upload-message-->

        <!--file-name-->
        <h4><strong>File name: </strong> {{ fileData.name }}</h4>
        <!--file-name-->

        <!--image-preview-->
        <v-img
          v-if="
            fileData.type === 'image/jpeg' ||
              fileData.type === 'image/png' ||
              fileData.type === 'image/jpg'
          "
          class="preview  mx-auto my-2"
          height="268"
          width="356"
          :src="installPhotoUrl"
        /><!--/image-preview-->
        <span class="text-caption"><i>Please wait until you can see the image to save the install.</i> </span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mx-2 my-4">
        <h4>To upload a new file press the button.</h4>
        <v-spacer></v-spacer>
        <!--reload-upload-button-->
        <v-btn color="secondary white--text" @click="uploadNewFile"
          >upload new file</v-btn
        >
        <!--/reload-upload-button-->
      </v-card-actions>
    </v-card>
    <!--/upload-message-->

    <v-dialog
      v-model="cameraDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="darkgrey" style="margin-top:100px !important">
          <v-toolbar-title>Take a photo</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="cameraDialog = false">
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="mx-0 px-0">
          <camera :storagePath="storagePath"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import camera from "./camera-view";
import { mapState } from "vuex";

export default {
  name: "GCSUpload",
  components: {
    camera,
  },
  props: {
    storagePath: {
        type: String
    }
  },
  data() {
    return {
      caption: "", //file description
      fileURL: "", //file gcs url
      fileData: null, //file data holder
      uploadValue: 0, //upload progress value
      isUploading: false, //upload progress bar
      hideUploadButton: false, // fisplay upload button after file is picked
      message: "", // upload message options
      messageIcon: "", // upload message options
      messageColor: "", // upload message options
      cameraDialog: false,
    };
  },
  computed: {
      ...mapState({
        installPhotoUrl: state => state.installPhotoUrl
      })
  },
  watch : {
    installPhotoUrl: {
      handler: function() {
        this.installPhotoUrl;
      },
      deep: true
    }
  },
  methods: {
    create() {
      this.isUploading = true; // show progress bar
      //console.log("file type: " + this.fileData.type);
      //TODO Add upload to MQTT here
      //console.log('FILE URL: ' + this.fileURL);
      
      this.hideUploadButton = true;
      this.message = "Your file was succesfully uploaded!";
      this.messageIcon = "mdi-ok";
      this.messageColor = "green";
    },
    //reset upload options
    uploadNewFile() {
      this.hideUploadButton = false;
      this.message = "";
      this.messageIcon = "";
      this.messageColor = "";
      this.isUploading = false;
      this.uploadValue = 0;
      this.fileData = null;
      this.$store.commit('setInstallPhotoUrl',  null);
    },
    //open file picker
    click1() {
      this.$refs.input1.click();
    },
    //show image preview
    previewFile(event) {
      this.uploadValue = 0;
      this.fileURL = null;
      console.log(JSON.stringify(event.target.files[0], null, 2));
      this.fileData = event.target.files[0]; // asign fileData for preview
      this.onUpload();
    },
    onUpload() {
      console.log("file data: " + JSON.stringify(this.fileData, null, 2));
      this.fileURL = null;
      // Create the file name
      //this.fileData.name =  `picture-upload-${new Date().getTime()}`;
      var name = this.storagePath + `/picture-upload-${new Date().getTime()}`;

      //GCS storage reference
      const storageRef = this.$firebase.storage
        .ref()
        .child(name)
        .put(this.fileData);

      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        }, //upload progress
        (error) => {
          console.log(error.message); //error
        },
        () => {
          this.uploadValue = 100; // upload value after loading file
          this.isUploading = false;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.fileURL = url; // asign GCS url to fileURL
            console.log(this.fileURL);
            this.$store.commit('setInstallPhotoUrl',  this.fileURL);
          });
        }
      );
      var t = this;
      setTimeout( function() {
        t.create();
      },500)
    },
    openCamera() {
      this.cameraDialog = true;
    },
  },
};
</script>

<style scoped>
.take-picture-button {
  position: fixed;
  right: 24px;
  bottom: 90px;
  z-index: 5;
}
</style>
