import firebase from "firebase/app";
import "firebase/storage";

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyB-ryQ4x4fFEtrjnMWtxPZnILIZK_FwfEc",
    authDomain: "tainstaller-b74f1.firebaseapp.com",
    databaseURL: "https://tainstaller-b74f1.firebaseio.com",
    projectId: "tainstaller-b74f1",
    storageBucket: "tainstaller-b74f1.appspot.com",
    messagingSenderId: "635441698016",
    appId: "1:635441698016:web:31b5ff23ce5b1bc379ec85",
    measurementId: "G-S86B7N3L56"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);


const storage = firebase.storage();

export { storage };
