<template>
  <v-app>
    <!-- App Bar -->
    <v-app-bar class="primary appbar" fixed app>
      <v-img @click="goTo('/')" style="cursor: pointer !important" :src="require('./assets/logo.png')" max-width="150"></v-img>
      <v-spacer></v-spacer>
      <v-icon class="px-4 white--text" @click="signOut()" v-if="$store.getters.showIcons === true">mdi-exit-run</v-icon>
      <v-icon class="white--text" @click="toggleSearch()" v-if="$store.getters.showIcons === true">icons8-search</v-icon>
    </v-app-bar>
        <v-banner height="48px" app sticky :class="{ 'success' : mqttServerStatus === 'Connected', 'error' : mqttServerStatus !== 'Connected' } " class="d-flex align-center noprint"  style="width: 100% !important;  position: fixed !important; z-index: 1000 !important;">
            <v-row no-gutters class="pa-2 ma-0 d-flex justify-center align-center" >
             <v-col v-if="mqttServerStatus === 'Connected'" cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="pa-0 ma-0 d-flex align-center justify-center">
               <div class="px-4 white--text">Connected to Cloud</div>   
             </v-col>
             <v-col v-if="mqttServerStatus !== 'Connected'" cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="pa-0 ma-0 d-flex align-center justify-center">
               <div class="px-4 white--text">Not Connected to Cloud</div>   
             </v-col>
           </v-row>
      </v-banner>        
       <v-banner height="48px" app sticky class="darkgrey d-flex align-center noprint"  v-if="updateExists" style="width: 100% !important;  position: fixed !important; z-index: 1000 !important;">
            <v-row no-gutters class="pa-2 ma-0 d-flex justify-center align-center" >
             <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="pa-0 ma-0 d-flex align-center justify-center">
               <div class="px-4 white--text">App Update Available</div>   
               <div class="px-4"><v-btn small @click="refreshApp" class="primary animate__animated animate__pulse animate__infinite"><span class="white--text">Update Now</span></v-btn></div>
             </v-col>
           </v-row>
    </v-banner>
    <!-- Main App Body -->
    <v-main class="grey lighten-3" style="margin-top: 50px !important;">
      <router-view />
    </v-main>
    <!-- <v-dialog
      v-model="passwordDialog"
      persistent
      fullscreen
      hide-overlay
      attach
        >
      <v-card>
        <v-card-text>
          <br /><br /><br /><br />
          Please enter your username and password below:<br /><br />
          <v-text-field v-model.trim="userAuth.username" filled label="Username" hide-details></v-text-field>
          <v-text-field v-model.trim="userAuth.password" filled label="Password" hide-details v-on:keyup.enter="checkLogin()"></v-text-field>
          <div class="mt-2 font-weight-bold error--text">{{ authAlert }}</div>
        </v-card-text>
        <v-card-actions class="px-6">

          <v-btn block
            color="primary darken-1"
            @click="checkLogin()"
          >
            Login
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'App',

  data: () => ({
    // // passwordDialog: true,
    // // userAuth: {
    // //   username: '',
    // //   password: ''
    // // },
    // // authAlert: ''
  }),
  computed: {
    ...mapState({
        //// auth: state => state.auth,
        mqttAction: state => state.mqttAction,
        // site: state => state.site,
        // controller: state => state.controller,
        // weather: state => state.weather,
        nodesStatus: state => state.nodesStatus,
        mqttServer: state => state.mqttServer,
        mqttServerStatus: state => state.mqttServerStatus,
        searchBox: state => state.searchBox,
        qrCodeBox: state => state.qrCodeBox,

    }),
  },
  watch: {
      mqttAction: {
          deep: true,
          handler() {
              console.log('New MQTT Action Recieved');
              switch (this.mqttAction.action) {
                  case 'publish':
                    var payload = JSON.parse(this.mqttAction.payload)
                    var message = new this.$Paho.Message(JSON.stringify(payload));
                     message.destinationName = this.mqttAction.topic;
                     message.retained = this.mqttAction.retain;
                     this.$mqttClient.send(message);
                    console.log(payload)
                    break;
              }
          }
      },
    
  },
  methods: {
    // // checkLogin() {
    // //   this.userAuth.username = this.userAuth.username.toLowerCase();
    // //   this.userAuth.password = this.userAuth.password.toLowerCase();
    // //   if ((this.auth.username === this.userAuth.username) && (this.auth.password === this.userAuth.password)) {   
    // //      localStorage.setItem('user-password', this.userAuth.password);
    // //     console.log('Username and Password Correct');
    // //     this.passwordDialog = false;
    // //     this.authAlert = '';
    // //   } else {
    // //     console.log('User and Pass Not Correct');
    // //     this.passwordDialog = true;
    // //     this.authAlert = 'Username/Password Incorrect';
    // //   }
    // // },
    // Toggle Search Box
    toggleSearch() {
      if (this.searchBox) {
        this.$store.commit('update_searchBox',  false)
      } else {
        this.$store.commit('update_searchBox',  true)
      }
      
      if (this.qrCodeBox) {
        this.$store.commit('update_qrCodeBox', false)
      }

    },
    // MQTT On Connect
    mqttOnConnect() {
      this.$store.commit('update_mqttServerStatus',  'Connected')
      console.log('Connected to Think Air MQTT')
      this.$mqttClient.subscribe("thinkair/nodes/#");
    },
    mqttOnFailure(error) {
      this.$store.commit('update_mqttServerStatus',  'Failed')
      console.log('mqtt server failed' + JSON.stringify(error, null, 2))
      // TODO - Reconnected on Failure
    },
    mqttOnMessage(message) {
      switch (true) {
        case message.destinationName.includes('thinkair/nodes/'):
            var topicSplit = message.destinationName.split('/');
            var category = topicSplit[topicSplit.length - 1];
            switch (category) {
                case 'config':
                  console.log('config update')
                  this.$store.dispatch('action_nodeConfig', message);
                  break;
                case 'status':
                  console.log('status update')
                  this.$store.dispatch('action_nodeStatus', message);
                  break;
                case 'install':
                  this.$store.dispatch('action_nodeInstall', message);
                  break;                                            
                case 'remote':
                  this.$store.dispatch('action_nodeRemote', message);
                  break;                                                                  
                case 'result':
                  this.$store.dispatch('action_nodeResult', message);
                  break;                                                                  
   
            }
            break;
        default:
            //console.log('Topic Not Matched');
            break;
        }
    },

    signOut(){
      localStorage.removeItem('user-password');
      this.$router.push("/auth").catch((error) => {
          console.log(error.message);
        });
    },
    goTo(path){
      this.$router.push(path).catch((error) => {
        console.log(error.message);
      });
    }
   
  },
  mounted() {
    var clientid = this.$moment().format('x') 
    this.$mqttClient = new this.$Paho.Client('tamosquitto.vindico.cloud', 8083, clientid);
    var options = {
        useSSL: true,
        userName: 'thinkair',
        password: 'TAsa13snMQTT',
        timeout: 3,
        onSuccess: this.mqttOnConnect,
        onFailure: this.mqttOnFailure,
    };
    this.$mqttClient.onMessageArrived = this.mqttOnMessage
    this.$mqttClient.connect(options);

  }
};
</script>

<style>
.theme--light.v-banner.v-sheet .v-banner__wrapper {
    border-bottom: none !important;
}
.borderred {  border: 1px solid red !important; }
.borderblue {  border: 1px solid blue !important; }
.bordergreen {  border: 1px solid green !important; }
.appbar {
                z-index: 9999 !important; 
            }
            .qrcodescanner {
              width: 100% !important;
              height: 200px !important;  
              border: 1px solid white;
            }
            /* .borderwhite {
              border: 2px solid white !important;
            } */
            .searchbar {
              z-index: 999 !important; 
            }
            .rounded {
              border-radius: 5px !important;

            }
             .iconstatus {
          border-radius: 5px !important; 
          width: 50px !important;
          height: 50px !important;
          display: flex;
      }
      .letterspacing {
        letter-spacing: -0.5px !important;
      }
            
</style>

