<template>
  <v-card>
    <v-card-text>
      <br /><br />
      Please enter your username and password below:<br /><br />
      <v-text-field
        v-model.trim="userAuth.username"
        filled
        label="Username"
        hide-details
      ></v-text-field>
      <v-text-field
        v-model.trim="userAuth.password"
        filled
        label="Password"
        hide-details
        v-on:keyup.enter="checkLogin()"
      ></v-text-field>
      <div class="mt-2 font-weight-bold error--text">{{ authAlert }}</div>
    </v-card-text>
    <v-card-actions class="px-6">
      <v-btn block color="primary darken-1" @click="checkLogin()">
        Login
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Auth",
  data: () => ({
    passwordDialog: true,
    userAuth: {
      username: "",
      password: "",
    },
    authAlert: "",
  }),
  computed: {
    ...mapState({
      auth: (state) => state.auth,
    }),
  },
  methods: {
    checkLogin() {
      this.userAuth.username = this.userAuth.username.toLowerCase();
      this.userAuth.password = this.userAuth.password.toLowerCase();
      if (
        this.auth.username === this.userAuth.username &&
        this.auth.password === this.userAuth.password
      ) {
        //localStorage.setItem("user-password", this.userAuth.password);
        this.$store.commit("setStoredPassword", this.userAuth.password);
        console.log("Username and Password Correct");
        // this.passwordDialog = false;
        this.authAlert = "";
        this.$router.push("/nodes").catch((error) => {
          console.log(error.message);
        });
      } else {
        console.log("User and Pass Not Correct");
        // this.passwordDialog = true;
        this.authAlert = "Username/Password Incorrect";
      }
    },
  },
};
</script>
