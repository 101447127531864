import Vue from "vue";
import VueRouter from "vue-router";
import Nodes from "../views/nodes.vue";
import NodeView from "../views/node_view.vue";
import Auth from "../views/auth.vue";
import store from "../store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "nodes" },
    meta: {
      requiresAuth: true,
    },
  },
  //login path
  {
    path: "/auth",
    component: Auth,
  },
  {
    path: "/nodes",
    name: "nodes",
    component: Nodes,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nodeview/:id",
    name: "nodeview",
    component: NodeView,
    meta: {
      requiresAuth: true,
    },
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  //mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    navigator.serviceWorker.register("/service-worker.js").then((reg) => {
      // sometime later…
      reg.update();
    });
    console.log(localStorage.getItem("user-password"));
    console.log('here: ' + store.getters.showIcons)
    if (!localStorage.getItem("user-password")) {
      store.commit("setShowIcons", false);
      next("/auth");
    } else {
      store.commit("setShowIcons", true);
      next();
    }
  } else next();
});

export default router;
