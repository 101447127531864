<template>
    <div class="white text-center bordergrey pa-2 dashcard">
        <div class="grey--text body-2 font-weight-bold">{{ title }}</div>
        <div 
            v-if="valueCheck" 
            :class="{
                'grey--text text--lighten-3': value === '-',
                'error--text': value === 'off',
                'success--text': value === 'on'      
            }"
            class="display-1 font-weight-bold text-uppercase"
        >{{ value }}</div>
        <div v-if="!valueCheck" class="success--text display-1 font-weight-bold text-uppercase">{{ value }}</div>
        <div class="caption grey--text">
            <div v-if="timeCheck">
                <div v-if="$moment().diff($moment(time, 'x'), timeUnits) > 10" class="error--text animate__animated animate__flash animate__infinite animate__slow">{{ this.$moment(time, 'x').fromNow() }}</div>
                <div v-if="$moment().diff($moment(time, 'x'), timeUnits) < 10">{{ this.$moment(time, 'x').fromNow() }}</div>
                <div v-else>-</div>
            </div>
            <div v-if="!timeCheck">
                <div>{{ this.$moment(time, 'x').fromNow() }}</div>
            </div>
        </div>
    </div>
</template>

<script>
module.exports = {
    props:
        {
            title: {
                type: String,
                default: () => '-',
            },
            value: {
            },
            time: {
                type: String,
                default: () => '-',
            },
            timeCheck: {
                type: Boolean,
                default: () => false,
            },
            valueCheck: {
                type: Boolean,
                default: () => false,
            },            
            timeError: {
                type: Number,
                default: () => 0,
            },
            timeSuccess: {
                type: Number,
                default: () => 0,
            },
            timeUnits: {
                type: String,
                default: () => 'seconds'
            }

        },
}
</script>