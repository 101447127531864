export const mixin = {
    data() {
        return {
            offline: false,   //* for service-worker
            refreshing: false,   //* for service-worker
            registration: null,   //* for service-worker
            updateExists: false,   //* for service-worker
        }
    },
    created() {
        //* for service-worker
        document.addEventListener('swUpdated', this.updateAvailable, { once: true })
        // This prevents Multiple Refreshes
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            // We'll also need to add 'refreshing' to our data originally set to false.
            if (this.refreshing) return
            this.refreshing = true
            // Here the actual reload of the page occurs
            window.location.reload() // auto refreshing
        })
    },
    methods: {
        // getMiniDetails: function(id){
        //     var minisDetailsIndex = this.minisDetails.findIndex(mini => mini.id === id);
        //     if (minisDetailsIndex !== -1) {
        //         return this.minisDetails[minisDetailsIndex].name;
        //     } else {
        //         return 'UNKNOWN';
        //     }
            
        // },
        sentMQTTAction(action, topic, payload, retain) {
            this.$store.commit('update_mqttAction', { action, topic, payload, retain });       
        },
        // createJSONStatus: function(value) {
        //     return {
        //         "value": value,
        //         "updated": this.$moment().format('x')
        //     }
        // },
        humanTime: function(date, formatIn, formatOut) {
            if (date !== undefined) {
                return this.$moment(date, formatIn).format(formatOut);
            } else {
                return 'INVALID DATE'
            }
        },
        humanDuration: function(duration, formatIn, formatOut) {
            if (duration !== undefined) {
                switch (true) {
                    case (formatIn === 'ms' && formatOut === 'secs'):
                        return parseInt(duration/1000)
                      
                    case (formatIn === 'ms' && formatOut === 'mins'):
                        return parseInt(duration/1000/60)
                        
                    default:
                        return 'UNKNOWN CONVERSION'
                }
            } else {
                return 'INVALID DURATION'
            }
        },
        
       //* service-worker methods
        start() {
            //window.location.reload()
        },
        // Store the SW registration so we can send it a message
        // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
        // To alert the user there is an update they need to refresh for
        updateAvailable(event) {
            this.registration = event.detail
            this.updateExists = true
        },
        refreshApp() {
            console.log('refreshing happening now')
            this.updateExists = false
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.registration || !this.registration.waiting) return
            // Send message to SW to skip the waiting and activate the new SW
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        }
    }
  }

  export default mixin;