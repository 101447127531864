var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mx-0 pa-0"},[(_vm.message === '')?_c('v-card',{staticClass:"mt-3",attrs:{"flat":"","width":"100%"}},[_c('v-card-title',{staticClass:"text-subtitle-1 mt-2 font-weight-bold"},[_vm._v("Upload a picture "),_c('v-spacer'),_c('v-btn',{staticClass:"secondary white--text",on:{"click":_vm.click1}},[_vm._v("upload file")])],1),_c('v-divider'),_c('v-card-text',[_c('input',{ref:"input1",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*;capture=camera"},on:{"change":_vm.previewFile}}),(_vm.fileData != null)?_c('div',[(
            _vm.fileData.type === 'image/jpeg' ||
              _vm.fileData.type === 'image/png' ||
              _vm.fileData.type === 'image/jpg'
          )?_c('v-img',{staticClass:"preview my-2 mx-auto",attrs:{"height":"268","width":"356","src":_vm.fileURL}}):_vm._e()],1):_vm._e(),(_vm.fileData != null)?_c('v-row',{staticClass:"mx-3"},[_c('h4',[_c('strong',[_vm._v("Selected file: ")]),_vm._v(_vm._s(_vm.fileData.name))]),_c('v-spacer'),(_vm.hideUploadButton === false)?_c('v-btn',{staticClass:"text-right mr-2",attrs:{"color":"red white--text"},on:{"click":_vm.uploadNewFile}},[_vm._v("cancel")]):_vm._e(),(_vm.hideUploadButton === false)?_c('v-btn',{staticClass:"text-right",attrs:{"color":"primary white--text"},on:{"click":_vm.create}},[_vm._v("upload")]):_vm._e()],1):_vm._e()],1),_c('v-card-actions',{staticClass:"mb-0 pb-0"},[(_vm.hideUploadButton === false && _vm.isUploading)?_c('v-row',{staticClass:"px-1 py-0 mb-0"},[_c('v-progress-linear',{attrs:{"height":"25"},model:{value:(_vm.uploadValue),callback:function ($$v) {_vm.uploadValue=$$v},expression:"uploadValue"}},[_c('strong',[_vm._v(_vm._s(Math.ceil(_vm.uploadValue))+"%")])])],1):_vm._e()],1)],1):_c('v-card',{staticClass:"mt-3"},[_c('v-card-title',{staticClass:"mx-4 py-4"},[_vm._v("Upload a file ")]),_c('v-divider'),_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"color":_vm.messageColor,"size":"100"}},[_vm._v(_vm._s(_vm.messageIcon))]),_c('br'),_c('h3',[_vm._v(_vm._s(_vm.message))]),_c('br'),_c('h4',[_c('strong',[_vm._v("File name: ")]),_vm._v(" "+_vm._s(_vm.fileData.name))]),(
          _vm.fileData.type === 'image/jpeg' ||
            _vm.fileData.type === 'image/png' ||
            _vm.fileData.type === 'image/jpg'
        )?_c('v-img',{staticClass:"preview  mx-auto my-2",attrs:{"height":"268","width":"356","src":_vm.installPhotoUrl}}):_vm._e(),_c('span',{staticClass:"text-caption"},[_c('i',[_vm._v("Please wait until you can see the image to save the install.")])])],1),_c('v-divider'),_c('v-card-actions',{staticClass:"mx-2 my-4"},[_c('h4',[_vm._v("To upload a new file press the button.")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary white--text"},on:{"click":_vm.uploadNewFile}},[_vm._v("upload new file")])],1)],1),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.cameraDialog),callback:function ($$v) {_vm.cameraDialog=$$v},expression:"cameraDialog"}},[_c('v-card',[_c('v-toolbar',{staticStyle:{"margin-top":"100px !important"},attrs:{"dark":"","color":"darkgrey"}},[_c('v-toolbar-title',[_vm._v("Take a photo")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.cameraDialog = false}}},[_c('v-icon',[_vm._v("mdi-window-close")])],1)],1)],1),_c('v-card-text',{staticClass:"mx-0 px-0"},[_c('camera',{attrs:{"storagePath":_vm.storagePath}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }