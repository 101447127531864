<template>
    <div>
      <v-tabs  v-model="tabs" left class="" show-arrows>
        <v-tab href="#mobile-tabs-5-1">Data</v-tab>
        <v-tab href="#mobile-tabs-5-2">Install</v-tab>
        <v-tab href="#mobile-tabs-5-3">QR Code</v-tab>
        <v-tab href="#mobile-tabs-5-4">Config</v-tab>
        <!--v-tab href="#mobile-tabs-5-5">Calibrate</v-tab-->
        <v-tab href="#mobile-tabs-5-6">Remote</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
      <v-tab-item value="mobile-tabs-5-1" class="pa-0">
        <v-card flat class="grey lighten-3 pa-0 ma-0">
          <v-card-text class="pa-0 ma-0">
            <v-row  no-gutters class="pt-4 px-3 flex" align="center" v-if="JSON.stringify(nodeStatus) !== '{}'">
              <v-col  cols="2">
                <div v-if="nodeStatus != undefined"
                  :class="{
                    'success': nodeStatus.code === 1,
                    'error': nodeStatus.code === -1,                             
                    }" 
                    class="iconstatus justify-center bordergrey"
                >
                  <v-icon class="text-h4 white--text">icons8-air-conditioner</v-icon>
                </div>
              </v-col>
              <v-col class="pa-2 pl-4">
                  <div class="text-h6 primary--text letterspacing font-weight-bold">
                    <span v-if="nodeInstall !== undefined">{{ nodeInstall.name }}</span>
                    <span v-if="nodeInstall === undefined && nodeStatus != undefined">{{ nodeStatus.id }}</span></div>
                  <div class="subtitle-1 text-caption"><strong>ID:</strong> {{ $route.params.id }}</div>
              </v-col>
              <v-col class="text-right">
                <v-btn align="right" to="/">Back</v-btn>
              </v-col>
              <v-col cols="12">
                <div v-if="nodeInstall !== undefined" class="subtitle-1 "><strong>Address:</strong>{{nodeInstall.location.address}}</div>
              </v-col>
            </v-row>
            <v-row  no-gutters class="pa-1" v-if="JSON.stringify(nodeStatus) !== '{}' && nodeStatus != undefined">
              <v-col cols="12" xs="12" sm="12" md="12" class="pa-2" v-if="nodeStatus.started !== undefined">
                <v-card flat class="pa-2">Last Restart: <strong>{{ humanTime(nodeStatus.started, 'x', 'DD MMM YYYY HH:mm') }}</strong> Online: <strong>{{ $moment(nodeStatus.started, 'x').fromNow(true) }}</strong></v-card>
              </v-col>
              <v-col v-for="sensor in nodeStatus.sensors" :key="sensor.name" cols="6" xs="6" sm="6" md="4" class="pa-2">
                <dashcard :title="sensor.name + ' ' + sensor.unit"  :value="sensor.value" :time="nodeStatus.updated.toString()" :time-check="false" :value-check="true" />
              </v-col>
              <v-col cols="6" xs="6" sm="6" md="4" class="pa-2">
                <dashcard title="FW Version"  :value="nodeStatus.version" :time="nodeStatus.updated.toString()" :time-check="false" :value-check="true" />
              </v-col>
              
            </v-row>
            <v-row v-if="nodeInstall !== undefined" class="pa-1" justify="space-around">
              <v-col cols="8">
                <v-img v-if="nodeInstall.photoURL !== undefined || nodeInstall.photoURL != ''"
                  :src="nodeInstall.photoURL"
                  aspect-ratio="1.7"
                  contain
                ></v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item value="mobile-tabs-5-2">
        <v-card flat class="grey lighten-3 pa-0 ma-0">
          <v-card-text>
            <v-row>
              <v-col cols="6" xs="6" class="text-h5 primary--text">
                Install
              </v-col>
              <v-col cols="6" xs="6" class="text-right">
                <v-btn v-if="reinstall" @click="clearNodeNewInstall">Re-install</v-btn>
              </v-col>
            </v-row>
          
            <div class="text-subtitle-1 mt-2 font-weight-bold">1. Set Install date</div>
              <v-menu v-if="nodeInstall != undefined" ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="nodeInstall.installDate" transition="scale-transition" offset-y min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field v-model="nodeNewInstall.installDate" label="Install date" append-inner-icon="mdi-calendar"  background-color="white" filled v-on="on"></v-text-field>
              </template>
               <v-date-picker v-model="nodeInstall.installDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                   <v-btn text color="primary" @click="$refs.menu.save(nodeInstall.installDate)">OK</v-btn>
               </v-date-picker>
            </v-menu>                     

            <div class="text-subtitle-1 mt-2 font-weight-bold">2. Set Name</div>
            <v-text-field v-model="nodeNewInstall.name"  hide-details class="" background-color="white" filled></v-text-field>
            <div v-if="nodeNewInstall.name !== ''">
              <div class="text-subtitle-1 mt-2 font-weight-bold">3. Set Location <span v-if="gettingLocation === true" class="animate__animated animate__flash animate__slower primary--text">(Getting Location)</span></div>
              <v-btn v-if="nodeNewInstall.location.lat === ''" class="primary" large @click="getLocation()" block>Set Location</v-btn>
              <v-img class="my-1" @click="openGoogleMap()" :src="imgmap" max-width="100%" />
              <div v-if="nodeNewInstall.location.lat !== ''">
                <v-text-field disabled v-model="nodeNewInstall.location.lat" hide-details background-color="white" filled label="Lattitude" append-icon="mdi-map-marker" class="my-1"></v-text-field>
                <v-text-field disabled v-model="nodeNewInstall.location.lon" hide-details background-color="white" filled label="Longitude" append-icon="mdi-map-marker" class="my-1"></v-text-field>
                <v-text-field v-model="nodeNewInstall.location.address" hide-details background-color="white" filled label="Address" append-icon="mdi-map-marker" class="my-1"></v-text-field>
              </div>
              <!-- <img src="https://taapi.vindico.cloud/api/staticmap" width="300" height="300">                -->
              <div v-if="locationError !== ''" class="error--text">{{ locationError }}</div>
            </div>
              
            <div class="text-subtitle-1 mt-2 font-weight-bold">Optional</div>
           
            <gcsUpload :storagePath="photoStoragePath"/>

            <!-- <v-row v-if="nodeInstall !== undefined && nodeInstall.photoURL != ''" class="pa-1" justify="space-around">
              <v-col cols="12">
                <v-img v-if="nodeInstall.photoURL !== undefined || nodeInstall.photoURL != ''"
                  :src="nodeInstall.photoURL"
                  aspect-ratio="1.7"
                  contain
                ></v-img>
              </v-col>
            </v-row> -->

            <div v-if="nodeNewInstall.location.lat !== ''">
              <div class="text-subtitle-1 mt-2 font font-weight-bold">4. Save Install</div>
              <v-btn class="primary" large block @click="saveInstall()">Save</v-btn>
              <v-snackbar top v-model="showInstallAlert" color="green" timeout="2000">
               {{snackBarText}}
               <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="status = false" > Close  </v-btn>
              </template>
               </v-snackbar>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item value="mobile-tabs-5-3">
        <v-card flat class="grey lighten-3 pa-0 ma-0">
          <v-card-text>
            <v-row>
              <v-col cols="6" xs="6" class="text-h5 primary--text">
                Generate QR
              </v-col>
              <v-col cols="6" xs="6" class="text-right">
                            <v-btn v-if="!qrCodeCreated" @click="createQRCode">Generate</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" class="text-center">
                <div ref="qrcode"></div>
                  <div v-if="qrCodeCreated">
                  <div class="text-center font-weight-bold subtitle-1 mb-2"><br />Print QR Code using Brother P-Touch Cube</div>
                  <ul class="text-left body-2" >
                      <li>Hold your finger down on the QR code</li>
                      <li>Select Share Image</li>
                      <li>Select <strong>Brother Print Sdk Demo</strong></li>
                      <li>Select <strong>Print</strong></li>
                  </ul>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item value="mobile-tabs-5-4">
        <v-card flat class="grey lighten-3 pa-0 ma-0">
          <v-card-text>
            <v-row>
              <v-col cols="6" xs="6" class="text-h5 primary--text">
                Config
              </v-col>
              <v-col cols="6" xs="6" class="text-right">

              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" xs="12" class="mb-1">
                <v-sheet class="text-left caption font-weight-normal pa-3">
                  <v-row no-gutters class="d-flex pa-0" align="center">
                    <v-col cols="9">SENSING ON<br /><span class="caption font-weight-normal">Node checks sensors.</span></v-col>
                    <v-col cols="2" xs="2">
                      <v-switch class="pl-8" v-model="nodeNewConfig.sensingOn" inset></v-switch>
                    </v-col>                    
                  </v-row>
                  <v-row no-gutters class="d-flex pa-0" align="center">
                    <v-col cols="9">REPORTING ON<br /><span class="caption font-weight-normal">Node does not Report to Cloud.</span></v-col>
                    <v-col cols="2" xs="2">
                      <v-switch class="pl-8" v-model="nodeNewConfig.reportingOn" inset></v-switch>
                    </v-col>                    
                  </v-row>
                  <v-row no-gutters class="d-flex pa-0" align="center">
                    <v-col cols="9">ALWAYS ON<br /><span class="caption font-weight-normal">Node does not sleep and loops on Check Interval.</span></v-col>
                    <v-col cols="2" xs="2">
                      <v-switch class="pl-8" v-model="nodeNewConfig.alwaysOn" inset></v-switch>
                    </v-col>                    
                  </v-row>
                  <v-row no-gutters class="d-flex pa-0" align="center">
                    <v-col cols="9">FANS ON<br /><span class="caption font-weight-normal">Allow the Fans on or off.</span></v-col>
                    <v-col cols="2" xs="2">
                      <v-switch class="pl-8" v-model="nodeNewConfig.fansOn" inset></v-switch>
                    </v-col>                
                  </v-row>                  
                </v-sheet>
              </v-col>
              <v-col cols="12" xs="12" class="text-left title">
                <v-text-field v-model="nodeNewConfig.checkInterval" :hint="'Frequency of Sensor Data - ' + (nodeNewConfig.checkInterval/1000/60).toFixed(2) + ' Min(s)'" persistent-hint background-color="white" filled label="CHECK INTERVAL (ms)" class="my-1"></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" class="text-left title">
                <v-text-field v-model="nodeNewConfig.senseCount" hint="Number of Sense Runs" persistent-hint background-color="white" filled label="SENSE COUNT" class="my-1"></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" class="text-left title">
                <v-text-field v-model="nodeNewConfig.senseIgnore" hint="Number of Sense Runs to Ignore" persistent-hint background-color="white" filled label="SENSE IGNORE" class="my-1"></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" class="text-left title">
                <v-text-field v-model="nodeNewConfig.senseInterval" hint="Interval between Sense Runs" persistent-hint background-color="white" filled label="SENSE INTERVAL (ms)" class="my-1"></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" class="text-left title">
                <v-text-field v-model="nodeNewConfig.fansDuration" hint="Fan Duration" persistent-hint background-color="white" filled label="FAN DURATION" class="my-1"></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" class="text-left title">
                <v-text-field v-model="nodeNewConfig.fansPort" hint="The Digital Port for the Fan" persistent-hint background-color="white" filled label="FAN PORT" class="my-1"></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" class="text-left title my-2">
                <v-btn block large class="primary" @click="updateConfig">Update</v-btn>
              </v-col>

              <v-col cols="12" xs="12" class="text-left title my-2" v-if="get_nodeResult($route.params.id)[0] !== undefined">
                <div v-if="get_nodeResult($route.params.id)[0].type === 'CONFIG'">
                <span v-if=" get_nodeResult($route.params.id) != undefined"
                  :class="{ 
                    'success--text': get_nodeResult($route.params.id)[0].code === 1,
                    'warning--text': get_nodeResult($route.params.id)[0].code === 0,
                    'error--text': get_nodeResult($route.params.id)[0].code === -1,
                  }"
                  class="body-2">{{ get_nodeResult($route.params.id)[0].message }}
                </span><br />
                <!-- <span v-if="get_nodeResult($route.params.id)[0].data">
                  SSH Host: {{ get_nodeResult($route.params.id)[0].data.substring(6, 99) }}
                </span> -->
                </div>
              </v-col>


              <v-snackbar top v-model="showConfigAlert" color="green" timeout="2000">
               {{snackBarText}}
               <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="status = false" > Close  </v-btn>
              </template>
               </v-snackbar>
            </v-row>            
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item value="mobile-tabs-5-5">
        <v-card flat class="grey lighten-3 pa-0 ma-0">
          <v-card-text>
            <v-row>
              <v-col cols="6" xs="6" class="text-h5 primary--text">
                Calibrate
              </v-col>
              <v-col cols="6" xs="6" class="text-right">

              </v-col>
            </v-row>
            <v-row no-gutters align="center">
              <v-col cols="6" xs="6" class="text-left title">
                Calibration:
              </v-col>
              <v-col cols="6" xs="6" class="text-right" justify="right">
                <v-switch v-model="nodeNewCalibration.status" inset></v-switch>
              </v-col>
              <v-col cols="12" xs="12" class="text-left title">
                <v-text-field v-model="nodeNewCalibration.offset.pm1" hide-details background-color="white" filled label="PM1 Offset" class="my-1"></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" class="text-left title">
                <v-text-field v-model="nodeNewCalibration.offset.pm25" hide-details background-color="white" filled label="PM2.5 Offset" class="my-1"></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" class="text-left title">
                <v-text-field v-model="nodeNewCalibration.offset.pm10" hide-details background-color="white" filled label="PM10 Offset" class="my-1"></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" class="text-left title my-2">
                <v-btn block large class="primary" @click="updateCalibration">Update</v-btn>
              </v-col>
            </v-row>            
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item value="mobile-tabs-5-6">
        <v-card flat class="grey lighten-3 pa-0 ma-0">
          <v-card-text>
            <v-row>
              <v-col cols="12" xs="12" class="text-h5 primary--text">
                Remote Control
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" xs="12" class="mb-1">
                <v-sheet class="text-left caption font-weight-normal pa-3">
                  <v-row no-gutters class="d-flex pa-0" align="center">
                    <v-col cols="8">Remote SSH</v-col>
                    <v-col cols="3" xs="3">
                      <span v-if="nodeRemote !== undefined">
                        <span class="success-text" v-if="nodeRemote.ssh">ENABLED</span>
                        <span class="grey-text" v-if="!nodeRemote.ssh">DISABLED</span>                       
                      </span>
                      <span v-if="nodeRemote === undefined">Unknown</span>
                    </v-col>                    
                  </v-row>
                </v-sheet>
              </v-col>
              <v-col v-if="nodeRemote !== undefined" cols="12" xs="12" class="text-left title my-2">
                <v-btn v-if="nodeRemote.ssh === false" block large class="primary" @click="enableRemote">Enable</v-btn>
                <v-btn v-if="nodeRemote.ssh === true" block large class="primary" @click="disableRemote">Disable</v-btn>
              </v-col>
              <v-col v-if="nodeRemote === undefined" cols="12" xs="12" class="text-left title my-2">
                <v-btn block large class="primary" @click="enableRemote">Enable</v-btn>
              </v-col>

              <v-col cols="12" xs="12" class="text-left title my-2" v-if="get_nodeResult($route.params.id)[0] !== undefined">
                <div v-if="get_nodeResult($route.params.id)[0].type === 'REMOTE'">
                <span v-if="get_nodeResult($route.params.id) != undefined"
                  :class="{ 
                    'success--text': get_nodeResult($route.params.id)[0].code === 1,
                    'warning--text': get_nodeResult($route.params.id)[0].code === 0,
                    'error--text': get_nodeResult($route.params.id)[0].code === -1,
                  }"
                  class="body-2">{{ get_nodeResult($route.params.id)[0].message }}
                </span><br />
                <span v-if="get_nodeResult($route.params.id)[0].data">
                  SSH Host: {{ get_nodeResult($route.params.id)[0].data.substring(6, 99) }}
                </span>
                </div>
              </v-col>
              <v-snackbar top v-model="showConfigAlert" color="green" timeout="2000">
               {{snackBarText}}
               <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="status = false" > Close  </v-btn>
              </template>
               </v-snackbar>
            </v-row>            
          </v-card-text>
        </v-card>
      </v-tab-item>                        
    </v-tabs-items>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import QRCode from 'easyqrcodejs'; 
import gcsUpload from '../components/file-upload/gcs-upload';

export default {
  components: {
    gcsUpload
  },
  data: () => ({
    photoStoragePath: 'install-mqtt',
    snackBarText: ' The node has been successfully updated ',
      showInstallAlert: false,
       showConfigAlert: false,
      tabs: 'mobile-tabs-5-1',
      size: 300,
      panel: null,
      qrCodeCreated: false,
    gettingLocation: false,
    reinstall: false,
    locationError: '',
    imgmap: '',
    //date: null,
    menu: false,
    nodeNewInstall: {
      id: '',
      name: '',
      photoURL: '',
      installDate: '',
      location: {
        lat: '',
        lon: '',
        address: ''
      },
      updated: ''
    },
    nodeNewCalibration: {
      id: '',
      status: true,
      offset: {
        pm1: '0',
        pm25: '0',
        pm10: '0'
      },
      updated: ''
    },
    nodeNewRemote: {
      id: '',
      ssh: false,
    },
    nodeNewConfig: {
      id: '',
      senseCount: 16,
      senseIgnore: 6,
      senseInterval: 10000,
      checkInterval: 300000,
      alwaysOn: true,
      sensingOn: true,
      reportingOn: true,
      fansOn: true,
      fansDuration: 10000,
      fansPort: 5,
      updated: ''
    }


  }),
  computed: {
      ...mapGetters(['get_nodeStatus','get_nodeInstall', 'get_nodeConfig', 'get_nodeRemote', 'get_nodeResult']),
      nodeStatus() {
        return this.get_nodeStatus(this.$route.params.id)[0]
      },
      nodeConfig() {
        console.log(this.$route.params.id)
        return this.get_nodeConfig(this.$route.params.id)[0]
      },
      nodeInstall() {
        var nodeInstall = this.get_nodeInstall(this.$route.params.id)[0]
        console.log('nodeInstall = ' + JSON.stringify(nodeInstall))
        return nodeInstall;
      },
      nodeRemote() {
        return this.get_nodeRemote(this.$route.params.id)[0]
      },
      location() {
          if (this.nodeNewInstall.location.lat !== '' && this.nodeNewInstall.location.lon !=='') {
              return `${this.nodeNewInstall.location.lat.toFixed(10)}, ${this.nodeNewInstall.location.lon.toFixed(1080.23569)}`
          } else {
              return  ''
          }
      }
  },
  watch: {
    nodeInstall: {
      deep: true,
      handler() {
        console.log('nodeInstall has been watched')
        if (this.nodeInstall !== undefined) {
          console.log('updating nodeInstall')
          this.nodeNewInstall = this.nodeInstall
          this.reinstall = true;
          this.getMapImage(this.nodeInstall.location.lat, this.nodeInstall.location.lon)
        }

      }
    },
  },
  methods: {
   
      save (date) {
        this.$refs.menu.save(date)
      },
    
    clearNodeNewInstall() {
      this.$store.commit('installPhotoUrl',  '');
      this.imgmap = '',
      this.nodeNewInstall = {
      id: '',
      name: '',
      photoURL: '',
      installDate: '',
      location: {
        lat: '',
        lon: '',
        address: ''
      },
      updated: ''
    }
    },
    enableRemote() {
      this.nodeNewRemote.id = this.nodeStatus.id;
      this.nodeNewRemote.ssh = true
      this.nodeNewRemote.updated = this.$moment().format('x');
      this.sentMQTTAction('publish', 'thinkair/nodes/' + this.nodeStatus.id + '/remote', this.nodeNewRemote, false);     
    },
    disableRemote() {
      this.nodeNewRemote.id = this.nodeStatus.id;
      this.nodeNewRemote.ssh = false
      this.nodeNewRemote.updated = this.$moment().format('x');
      this.sentMQTTAction('publish', 'thinkair/nodes/' + this.nodeStatus.id + '/remote', this.nodeNewRemote, false);     
    },

    updateConfig() {
      this.showConfigAlert = true;
      this.nodeNewConfig.id = this.nodeStatus.id;
      this.nodeNewConfig.updated = this.$moment().format('x');
      this.sentMQTTAction('publish', 'thinkair/nodes/' + this.nodeStatus.id + '/config', this.nodeNewConfig, true);     
    },
    updateCalibration() {
      this.nodeNewCalibration.id = this.nodeStatus.id;
      this.nodeNewCalibration.updated = this.$moment().format('x');
      this.sentMQTTAction('publish', 'thinkair/nodes/' + this.nodeStatus.id + '/calibration', this.nodeNewCalibration, true);           
    },    
    saveInstall() {
      this.showInstallAlert = true;
      //console.log( 'here: ' + this.$store.getters.installPhotoUrl)
      this.nodeNewInstall.photoURL = this.$store.getters.installPhotoUrl;
     // this.nodeNewInstall.installDate = '' //this.$moment().format('x');
     //this.nodeNewInstall.installDate = this.date;
      this.nodeNewInstall.updated = this.$moment().format('x');
      this.sentMQTTAction('publish', 'thinkair/nodes/' + this.nodeStatus.id + '/install', this.nodeNewInstall, true);           
    },
    openGoogleMap() {
        window.location.href = `maps://maps.google.com/maps?daddr=${this.nodeNewInstall.location.lat},${this.nodeNewInstall.location.lon}&amp;ll=`
    },
    axiosPostURL: function(url, body) {
        var v = this
        return new Promise(function(resolve) {
            console.log('body2 = ' + JSON.stringify(body, null, 2))
            v.$axios.post(url, body)
                .then(axiosPostResult => {
                    resolve(axiosPostResult.data)
                })
                .catch(axiosPostError => {
                    const status = { code: -1, message: 'ERROR with Axios Get', data: null.data, error: axiosPostError }
                    return resolve (status);    
                })
        })

    },
    axiosGetURL: function(url) {
        var v = this
        return new Promise(function(resolve) {
            v.$axios.get(url)
                .then(axiosGetResult => {
                    resolve(axiosGetResult.data)
                })
                .catch(axiosGetError => {
                    const status = { code: -1, message: 'ERROR with Axios Get', data: null.data, error: axiosGetError }
                    return resolve (status);    
                })
        })

    },    
    getLatLon: function() {
        return new Promise(function(resolve) {
            navigator.geolocation.getCurrentPosition(pos => {
                var data = {}                
                data.lat = pos.coords.latitude;
                data.lon = pos.coords.longitude;
                const status = { code: 1, message: 'LatLon Get Successfull', data: data, error: null }
                return resolve (status);    
            }, error => {
                const status = { code: -1, message: 'ERROR Could Not Get Latitude and Longitude, please check your Location Settings are Enabled', data: null, error: error.message }
                return resolve (status);    
            })            
        });
    },
    getLocation: async function() {
      this.gettingLocation = true;
        let latlonResult = await this.getLatLon();
        if (latlonResult.code === 1) {
            var url = `https://taapi.vindico.cloud/api/geocode`;
            var body = {}
            body.lat = latlonResult.data.lat;
            body.lon = latlonResult.data.lon;
            body.pass = "TAsa13sn!"
            let locationResult = await this.axiosPostURL(url, body);
            if (locationResult.code === -1) {
              this.locationError = locationResult.message
              this.gettingLocation = false
            } else {
              body.zoom = 17;
              body.size = '400x400';
              this.nodeNewInstall.id = this.nodeStatus.id;
              this.nodeNewInstall.location.lat = body.lat;
              this.nodeNewInstall.location.lon = body.lon;
              this.nodeNewInstall.location.address = locationResult.data.results[0].formatted_address;
              let mapImageResult = await this.axiosPostURL('https://taapi.vindico.cloud/api/staticmap', body);
              if (mapImageResult.code === 1) {
                this.imgmap =  'data:image/png;base64, ' + mapImageResult.data;
                this.gettingLocation = false;
              } else {
                this.locationError = mapImageResult.message
                this.gettingLocation = false

                  // show error
              }
            }
//            this.$refs.imgmap.src = 'data:image/png;base64, ' + map
            // console.log(map)
            // if (location.code === 1) {
            //     console.log(JSON.stringify(location, null, 2))
            // }
        } else {
          this.locationError = latlonResult.message
          this.gettingLocation = false
        }

    },
    getMapImage: async function(lat, lon) {
      var body = {}
      body.lat = lat;
      body.lon = lon;
      body.pass = "TAsa13sn!"
      body.zoom = 17;
      body.size = '400x400';
      let mapImageResult = await this.axiosPostURL('https://taapi.vindico.cloud/api/staticmap', body);
      if (mapImageResult!=undefined && mapImageResult.code === 1) {
        this.imgmap =  'data:image/png;base64, ' + mapImageResult.data;
      }
    },
      createQRCode() {
        var options = {
        text: this.$route.params.id,
        title: this.$route.params.id ,
        titleHeight: 75,
        titleTop: 50,
        titleFont: 'Bold 26.5px Arial',
        // subTitle: 'SN: ' + this.value,
        // subTitleFont: '36px Arial',
        // subTitleTop: 100,
        width: 256,
        height: 256
      //Create new QRCode Object
      }
      new QRCode(this.$refs.qrcode, options);
      this.qrCodeCreated = true;
    },
    populateNodeInstallForm(){
       if (this.nodeInstall !== undefined) {
          console.log('updating nodeInstall')
          //this.date = this.nodeInstall.installDate;
          this.nodeNewInstall = this.nodeInstall;
          this.reinstall = true;
          this.getMapImage(this.nodeInstall.location.lat, this.nodeInstall.location.lon)
        }

    }
    
  },
  created() {
      console.log('node id = ' + this.$route.params.id )
  },
  mounted() {
    if(this.nodeConfig != undefined){
      this.nodeNewConfig = this.nodeConfig;
      //this.nodeNewConfig.installDate = new Date(this.nodeNewConfig.installDate).toJSON()
    }
    
    this.populateNodeInstallForm();
    if(this.get_nodeResult(this.$route.params.id) != undefined){
      console.log('nodeResult: ' + JSON.stringify(this.get_nodeResult(this.$route.params.id)[0], null, 2))
    }
  }
}
</script>

<style>
.v-expansion-panel-content__wrap { padding: 10px !important;}
</style>


