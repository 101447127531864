import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment';

//import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  // plugins: [createPersistedState({
  //   paths: ['nodesStatus', 'mqttServerStatus'] // these ones you want to save on refresh / F5 what not
  // })],
  state: {
    minisDetails: [],
    nodesStatus: [],
    nodesInstall: [],
    nodesCalibration: [],
    nodesConfig: [],
    nodesRemote: [],
    nodesResult: [],
    mqttServerStatus: 'Connecting',
    mqttServer: {
      host: '',
      port: '',
    },
    searchBox: false,
    qrCodeBox: false,
    mqttAction: null,
    auth: {
      username: 'installer',
      password: 'tainstaller!',
    },
    //* saving password to localStorage
    storedPassword: '',
    showIcons: false,
    installPhotoUrl: ''
  },
  mutations: {
    //* saving password to localStorage
    setStoredPassword(state, payload){
      state.storedPassword = payload
      localStorage.setItem('user-password', payload)
    },
    setShowIcons(state, payload) {
      state.showIcons = payload;
    },
    setInstallPhotoUrl(state, payload) {
      state.installPhotoUrl = payload;
    },
    //mqttServerStatus
    update_mqttServerStatus (state, data) { state.mqttServerStatus = data },
    //mqttServer
    update_mqttServer (state, data) { state.mqttServer = data; },
    // nodesResult
    create_nodesResult (state, { payload }) { state.nodesResult.push (payload) },
    update_nodesResult (state, { index, payload }) { Vue.set(state.nodesResult, index, payload) },
    delete_nodesResult (state, { index }) { state.nodesResult.splice(index, 1) },
    // nodesStatus
    create_nodesStatus (state, { payload }) { state.nodesStatus.push (payload) },
    update_nodesStatus (state, { index, payload }) { Vue.set(state.nodesStatus, index, payload) },
    delete_nodesStatus (state, { index }) { state.nodesStatus.splice(index, 1) },
    // nodesInstall
    create_nodesInstall (state, { payload }) { state.nodesInstall.push (payload) },
    update_nodesInstall (state, { index, payload }) { Vue.set(state.nodesInstall, index, payload) },
    delete_nodesInstall (state, { index }) { state.nodesInstall.splice(index, 1) },    
    // nodesCalibration
    create_nodesCalibration (state, { payload }) { state.nodesCalibration.push (payload) },
    update_nodesCalibration (state, { index, payload }) { Vue.set(state.nodesCalibration, index, payload) },
    delete_nodesCalibration (state, { index }) { state.nodesCalibration.splice(index, 1) },    
    // nodesConfig
    create_nodesConfig (state, { payload }) { state.nodesConfig.push (payload) },
    update_nodesConfig (state, { index, payload }) { Vue.set(state.nodesConfig, index, payload) },
    delete_nodesConfig (state, { index }) { state.nodesConfig.splice(index, 1) },    
    // nodesRemote
    create_nodesRemote (state, { payload }) { state.nodesRemote.push (payload) },
    update_nodesRemote (state, { index, payload }) { Vue.set(state.nodesRemote, index, payload) },
    delete_nodesRemote (state, { index }) { state.nodesRemote.splice(index, 1) },    
    //searchBox
    update_searchBox (state, data) { state.searchBox = data },
    //qrBox
    update_qrCodeBox (state, data) { state.qrCodeBox = data },
    // mqttAction
    update_mqttAction (state, { action, topic, payload, retain }) {
      let mqttAction = {}
      mqttAction.action = action;
      mqttAction.topic = topic;
      mqttAction.payload = JSON.stringify(payload);
      mqttAction.retain = retain;
      state.mqttAction = mqttAction;
  },
  },
  getters: {

    //* Get stored password/show icons
    storedPassword(state) {
      return state.storedPassword;
    },
    showIcons(state) {
      return state.showIcons;
    },
    installPhotoUrl(state){
      return state.installPhotoUrl;
    },
    // ! Get Nodes Status 
      get_nodesStatus: (state) => () => { 
        return state.nodesStatus;
    },
   
    // Get Node Status
    get_nodeStatus: (state) => (id) => { 
      return state.nodesStatus.filter(node => node.id === id) 
    },
    // Get Node Status
    get_nodeInstall: (state) => (id) => {
      return state.nodesInstall.filter(node => node.id === id)
    },
    // Get Node Calibration
    get_nodeCalibration: (state) => (id) => {
      return state.nodesCalibration.filter(node => node.id === id)
    },
    // Get Node Config
    get_nodeConfig: (state) => (id) => {
      return state.nodesConfig.filter(node => node.id === id)
    },
    // Get Node Remote
    get_nodeRemote: (state) => (id) => {
      return state.nodesRemote.filter(node => node.id === id)
    },
    // Get Node Result
    get_nodeResult: (state) => (id) => {
      return state.nodesResult.filter(node => node.id === id)
    }
  },
  actions: {
    // nodeStatus
    action_nodeStatus({commit}, message) {
      var topicSplit = message.destinationName.split('/');
      var id = topicSplit[topicSplit.length - 2];
      var payload = JSON.parse(message.payloadString); // PARSE PAYLOAD
      var index = this.state.nodesStatus.findIndex(i => i.id === id); // FIND INDEX OF MINI DETAILS
      var timeNow = moment();
      var timeEnd = moment(payload.updated, 'x')
      switch (true) {
          case (timeNow.diff(timeEnd, 'minutes') > 30):
              payload.code = -1;
              break;
          case (timeNow.diff(timeEnd, 'minutes') < 30):
              payload.code = 1;
              break;
          default:
              payload.code = 0;
              break;
      }
      // IF NODE STATUS DOES NOT EXIST
      if (index === -1) {
          // CREATE NODE STATUS
          commit('create_nodesStatus', { payload })
          //this.nodesStatus.push (payload)
      } else {
          if (JSON.stringify(payload) === '{}') {
              // DELETE NODE STATUS
              commit('delete_nodesStatus', { index })                            
              // this.nodesStatus.splice(index, 1);
          } else {
              // UPDATE NODE STATUS
              commit('update_nodesStatus', { index, payload })                            
              //this.$set(this.nodesStatus, index, payload);                        
          }
      }
    },
    // nodeInstall
    action_nodeInstall({commit}, message) {
      var topicSplit = message.destinationName.split('/');
      var id = topicSplit[topicSplit.length - 2];
      var payload = JSON.parse(message.payloadString); // PARSE PAYLOAD
      var index = this.state.nodesInstall.findIndex(i => i.id === id); // FIND INDEX OF MINI DETAILS
      // IF NODE STATUS DOES NOT EXIST
      if (index === -1) {
          commit('create_nodesInstall', { payload }) // CREATE NODE STATUS
      } else {
          if (JSON.stringify(payload) === '{}') {
              commit('delete_nodesInstall', { index }) // DELETE NODE STATUS                            
          } else {
              commit('update_nodesInstall', { index, payload }) // UPDATE NODE STATUS                            
          }
      }
    },    
    // nodeInstall
    action_nodeCalibration({commit}, message) {
      var topicSplit = message.destinationName.split('/');
      var id = topicSplit[topicSplit.length - 2];
      var payload = JSON.parse(message.payloadString); // PARSE PAYLOAD
      var index = this.state.nodesCalibration.findIndex(i => i.id === id); // FIND INDEX OF MINI DETAILS
      // IF NODE STATUS DOES NOT EXIST
      if (index === -1) {
          commit('create_nodesCalibration', { payload }) // CREATE NODE STATUS
      } else {
          if (JSON.stringify(payload) === '{}') {
              commit('delete_nodesCalibration', { index }) // DELETE NODE STATUS                           
          } else {
              commit('update_nodesCalibration', { index, payload }) // UPDATE NODE STATUS
          }
      }
    },
    // nodeConfig Action
    action_nodeConfig({commit}, message) {
      var topicSplit = message.destinationName.split('/');
      var id = topicSplit[topicSplit.length - 2];
      var payload = JSON.parse(message.payloadString); // PARSE PAYLOAD
      var index = this.state.nodesConfig.findIndex(i => i.id === id); // FIND INDEX OF NODE CONFIG
      // IF NODE CONFIG DOES NOT EXIST
      if (index === -1) {
          commit('create_nodesConfig', { payload }) // CREATE NODE CONFIG
      } else {
          if (JSON.stringify(payload) === '{}') {
              commit('delete_nodesConfig', { index }) // DELETE NODE CONFIG                           
          } else {
              commit('update_nodesConfig', { index, payload }) // UPDATE NODE CONFIG
          }
      }
    },    
    // nodeRemote Action
    action_nodeRemote({commit}, message) {
      var topicSplit = message.destinationName.split('/');
      var id = topicSplit[topicSplit.length - 2];
      var payload = JSON.parse(message.payloadString); // PARSE PAYLOAD
      var index = this.state.nodesRemote.findIndex(i => i.id === id); // FIND INDEX OF NODE REMOTE
      // IF NODE REMOTE DOES NOT EXIST
      if (index === -1) {
          commit('create_nodesRemote', { payload }) // CREATE NODE REMOTE
      } else {
          if (JSON.stringify(payload) === '{}') {
              commit('delete_nodesRemote', { index }) // DELETE NODE REMOTE                           
          } else {
              commit('update_nodesRemote', { index, payload }) // UPDATE NODE REMOTE
          }
      }
    },
    // nodeResult Action
    action_nodeResult({commit}, message) {
      var topicSplit = message.destinationName.split('/');
      var id = topicSplit[topicSplit.length - 2];
      var payload = JSON.parse(message.payloadString); // PARSE PAYLOAD
      var index = this.state.nodesResult.findIndex(i => i.id === id); // FIND INDEX OF NODE RESULT
      // IF NODE RESULT DOES NOT EXIST
      if (index === -1) {
          commit('create_nodesResult', { payload }) // CREATE NODE RESULT
      } else {
          if (JSON.stringify(payload) === '{}') {
              commit('delete_nodesResult', { index }) // DELETE NODE RESULT                           
          } else {
              commit('update_nodesResult', { index, payload }) // UPDATE NODE RESULT
          }
      }      
    },    
  },
  modules: {
  }
})
