import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import moment from 'moment';
import { sortBy, filter } from 'lodash-es';

Vue.prototype.$sortBy = sortBy;
Vue.prototype.$filter = filter;


Vue.prototype.$moment = moment;
import './assets/thinkair/css/styles.min.css';
import '../node_modules/animate.css/animate.min.css';

// QR Code Reader
import VueQrcodeReader from "vue-qrcode-reader";
Vue.use(VueQrcodeReader);

import axios from 'axios';
Vue.prototype.$axios = axios;

// // QR Code Generator
// import * as QRCode from 'easyqrcodejs';
// Vue.prototype.$qrCode = QRCode;
// Vue.component('qrcode-vue', QRCode)


//* Service worker
import './registerServiceWorker'
import VueOffline from 'vue-offline'
import mixin from './mixins/';

Vue.use(VueOffline, {
  mixin: true
})

Vue.mixin(mixin);

//firebase
const firebase = require("./firebase/firebase-config.js");
Vue.prototype.$firebase = firebase;

// Vue.mixin({
//   methods: {
//     sentMQTTAction(action, topic, payload) {
//       this.$store.commit('update_mqttAction', { action, topic, payload });       
//     },
//   },
//   mounted() {
//    }
// });

import Paho from 'paho-mqtt';
Vue.prototype.$Paho = Paho;

import Dashcard from './components/dashcard.vue'

Vue.component('dashcard', Dashcard)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
