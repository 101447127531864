<template>
  <div class="camera-modal" v-if="imageUrl === ''">
    <video ref="video" class="camera-stream" />
    <div class="text-center">
      <v-btn @click="capture()" class="mx-2" fab dark large color="primary">
        <v-icon dark>
          mdi-camera
        </v-icon>
      </v-btn>
      <v-btn
        v-if="$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly"
        @click="switchCamera()"
        class="mx-2"
        fab
        dark
        large
      >
        <v-icon dark>
          mdi-camera-switch
        </v-icon>
      </v-btn>
    </div>
  </div>
  <div v-else class="text-center">
    <div v-if="message === ''">
      <v-img
        :src="imageUrl"
        class="preview my-2 mx-auto"
        height="268"
        width="356"
      ></v-img>
      <!-- <v-row class=" ma-4 text-center">
        <v-text-field v-model="caption" label="Write an image description">
        </v-text-field>
      </v-row> -->
      <v-row class="mx-2">
        <v-spacer></v-spacer>

        <v-btn
          class="text-right mr-2"
          color="primary white--text"
          v-if="hideUploadButton === false"
          @click="uploadToMQTT"
          >upload</v-btn
        >
      </v-row>
    </div>
    <div class="mt-3" v-else>
      <div class="text-center">
        <!--upload-message-->
        <v-icon :color="messageColor" size="100">{{ messageIcon }}</v-icon>
        <br />
        <h3>{{ message }}</h3>
        <br />
        <!--/upload-message-->

        <!--file-name-->
        <h4><strong>File name: </strong> {{ fileName }}</h4>
        <!--file-name-->

        <!--image-preview-->
        <v-img
          v-if="
            file.type === 'image/jpeg' ||
              file.type === 'image/png' ||
              file.type === 'image/jpg'
          "
          class="preview  mx-auto my-2"
          height="268"
          width="356"
          :src="imageUrl"
        /><!--/image-preview-->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "cameraView",
  props: {
    storagePath: {
        type: String
    }
  },
  data() {
    return {
      mediaStream: null,
      url: null,
      fileName: "",
      imageUrl: "",
      uploadValue: 0,
      caption: "", 
      file: "",
      isUploading: false, //upload progress bar
      hideUploadButton: false, // fisplay upload button after file is picked
      message: "", // upload message options
      messageIcon: "", // upload message options
      messageColor: "", // upload message options
      showBackCamera: false,
      videoVar: { video: true },
    };
  },
  watch: {
    videoVar() {
      this.startCamera();
    },
  },
  methods: {
    capture() {
      console.log("in capture");
      const mediaStreamTrack = this.mediaStream.getVideoTracks()[0];
      const imageCapture = new window.ImageCapture(mediaStreamTrack);
      this.fileName = `picture-camera-${new Date().getTime()}`;
      var name = this.storagePath + `/picture-camera-${new Date().getTime()}`;
      return imageCapture.takePhoto().then((blob) => {
        this.file = blob;
        const storageRef = this.$firebase.storage
          .ref()
          .child(name)
          .put(blob);

        storageRef.on(
          `state_changed`,
          (snapshot) => {
            this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          }, //upload progress
          (error) => {
            console.log(error.message); //error
          },
          () => {
            this.uploadValue = 100; // upload value after loading file
            this.isUploading = false;
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              this.imageUrl = url; // asign GCS url to fileURL
              console.log(this.imageUrl);
               this.$store.commit('setInstallPhotoUrl',  this.imageUrl);

            });
          }
        );
        // console.log(JSON.stringify(this.file, null,2));
        console.log(blob);
      });
    },
    uploadToMQTT() {
      this.isUploading = true; // show progress bar
     
      this.hideUploadButton = true;
          this.message = "Your file was succesfully uploaded!";
          this.messageIcon = "mdi-ok";
          this.messageColor = "green";
     
    },
    cancel() {
      this.hideUploadButton = false;
      this.message = "";
      this.messageIcon = "";
      this.messageColor = "";
      this.isUploading = false;
      this.uploadValue = 0;
      this.imageUrl = "";
      this.file = null;
    },
    switchCamera() {
      this.showBackCamera = !this.showBackCamera;
      console.log(this.showBackCamera);
      if (this.showBackCamera === true) {
        this.videoVar = { video: { facingMode: { exact: "environment" } } };
      } else {
        this.videoVar = { video: true };
      }
    },
    stopCamera() {
      return (
        this.$refs.video.srcObject &&
        this.$refs.video.srcObject.getTracks().map((t) => t.stop())
      );
    },
    startCamera() {
      this.stopCamera();
      var self = this;
      navigator.mediaDevices
        .getUserMedia(self.videoVar)
        .then((mediaStream) => {
          this.$refs.video.srcObject = mediaStream;
          this.$refs.video.play();
          this.mediaStream = mediaStream;
        })
        .catch((error) => console.error("getUserMedia() error:", error));
    },
  },
  mounted() {
    this.startCamera();
  },
  destroyed() {
    const tracks = this.mediaStream.getTracks();
    tracks.map((track) => track.stop());
  },
};
</script>

<style scoped>
.camera-modal {
  width: 100%;
  height: 90%;
  top: 7%;
  left: 0;
  position: absolute;
  background-color: white;
  z-index: 10;
}
.camera-stream {
  width: 100%;
  max-height: 90%;
}
</style>
