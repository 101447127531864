<template>
  <div>
          <!-- Search Box Banner -->
      <transition
         name="custom-classes-transition"
         enter-active-class="animate__animated animate__slideInDown animate__faster"
         leave-active-class="animate__animated animate__slideOutUp animate__faster"
         mode="out-in"
       >
         <v-banner class="searchbar grey darken-2 white--text body-2 font-weight-light" 
           v-if="searchBox"
           single-line
           :sticky="true"
           app
         >
           <v-row class="py-2 no-gutters align-center">
             <v-col cols="10" xs="10" class="">
               <v-text-field  v-if="!qrCodeBox" class="rounded white pa-2" style="text-decoration: none;" hide-details flat clearable placeholder="Search Name" v-model="searchName"></v-text-field>
               <v-text-field  v-if="qrCodeBox" class="rounded white pa-2" style="text-decoration: none;" hide-details flat clearable placeholder="Search ID" v-model="searchId"></v-text-field>
             </v-col>
             <v-col cols="2" xs="2" class=" text-right"><v-btn text @click="toggleQRCodeBox()"><v-icon class="white--text display-1">icons8-qr-code</v-icon></v-btn></v-col>                   
           </v-row>
          </v-banner>
      </transition>
      <!-- QR Box Banner -->
      <transition
        name="custom-classes-transition"
        enter-active-class="animate__animated animate__slideInDown animate__faster"
        leave-active-class="animate__animated animate__slideOutUp animate__faster"
        mode="out-in"
      >
        <v-banner class="grey darken-2 white--text body-2 font-weight-light" 
          v-if="qrCodeBox"
          single-line
          :sticky="true"
          app
        >
          <v-row class="py-2 no-gutters align-center" >
            <v-col cols="12" xs="12" class="text-center">
                <qrcode-stream @decode="onDecode" class="qrcodescanner"></qrcode-stream>
            </v-col>                 
          </v-row>   
        </v-banner>
      </transition>
      <v-row class="text-left">
        <v-col cols="12" xs="12">
          <v-row no-gutters>
            <v-col cols="4" xs="4" sm="4" md="1">
              <v-card class="pa-4 ma-2 text-center success--text" @click="selectValue = 1">
                <span class="display-1 font-weight-bold">{{ nodesOnline }}</span><br />
                <span class="">ONLINE</span>
              </v-card> 
            </v-col>
            <v-col cols="4" xs="4" sm="4" md="1">
              <v-card class="pa-4 ma-2 text-center error--text" @click="selectValue = -1">
                <span class="display-1 font-weight-bold">{{ nodesOffline }}</span><br />
                <span class="">OFFLINE</span>
              </v-card> 
            </v-col>
            <v-col cols="4" xs="4" sm="4" md="1">
              <v-card class="pa-4 ma-2 text-center primary--text" @click="selectValue = 0">
                <span class="display-1 font-weight-bold">{{ nodesTotal }}</span><br />
                <span class="">TOTAL</span>
              </v-card> 
            </v-col>            
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" xs="6" sm="4" md="4" v-for="item in filterNodes" :key="item.id">
              <v-card class="pa-2 mx-2 my-1 d-flex" flat :to="'/nodeview/'+item.id">
                <div 
                  :class="{
                    'success': item.code === 1,
                    'error':  item.code === -1                       
                    }" 
                    class="iconstatus justify-center bordergrey"
                >
                  <v-icon class="white--text">icons8-air-conditioner</v-icon>
                </div>
                <div class="body-2 px-2 font-weight-normal darkgrey--text ">
                    <span v-if="returnNodeName(item.id) !== undefined">
                      {{ returnNodeName(item.id) }}<br />
                      <span class="overline">{{ item.id }}</span><br />
                    </span>
                    <span v-else class="overline">{{ item.id }}</span>
                </div>
                <v-spacer/>
                <div class="text-right">
                    <span class="caption">{{ $moment(item.updated, 'x').fromNow('true') }}</span><br />
                    <span class="caption">v {{ item.version }}</span>
                </div>
              </v-card>
            </v-col>
          </v-row>
            
          <!--v-virtual-scroll
            :items="filteredNodes"
            :item-height="70"
            style="position: fixed; width:100%; height:80vh;">
          >
            <template v-slot="{ item }">
              <v-list-item class="white ma-4" style="border-radius: 5px; cursor: pointer;" :to="'/nodeview/'+item.id">
                <v-list-item-icon>
                  <div 
                  :class="{
                    'success': ($moment().diff($moment(item.updated, 'x'), 'seconds') < 1800),
                    'error': ($moment().diff($moment(item.updated, 'x'), 'seconds') > 1800),                             
                    }" 
                    class="iconstatus justify-center bordergrey"
                >
                  <v-icon class="white--text">icons8-air-conditioner</v-icon>
                  </div>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="grey--text text--darken-2">
                    <span v-if="returnNodeName(item.id) === undefined">{{ item.id }}</span>
                    <span v-else-if="returnNodeName(item.id) !== undefined">{{ returnNodeName(item.id) }}</span>
                    
                  </v-list-item-title>
                  
                <v-list-item-subtitle>
                  <span class="success--text"  v-if="($moment().diff($moment(item.updated, 'x'), 'seconds') < 1800)">Online</span>
                    <span class="error--text" v-else-if="($moment().diff($moment(item.updated, 'x'), 'seconds') > 1800)">Offline</span>
                    </v-list-item-subtitle>
                  <v-list-item-subtitle>{{ $moment(item.updated, 'x').fromNow() }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-btn :to="'/nodeview/'+item.id" tag="v-icon" text class="flat grey--text text-h5 text-right"><v-icon>mdi mdi-chevron-right</v-icon></v-btn>
                </v-list-item-icon>
              </v-list-item>
            </template>
          </v-virtual-scroll-->
        </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'App',

  components: {
    //HelloWorld,
  },

  data: () => ({
    searchName: '',
    searchId: '',
    filterNodes: [],
    filterValues: [
      { text: 'All', value: 0 },
      { text: 'Offline', value: -1 },
      { text: 'Online', value: 1 }
    ],
    selectValue: 0
    //
  }),
  computed: {
    ...mapGetters(['get_nodeInstall', 'get_nodesStatus']),
    ...mapState({
        // mqttAction: state => state.mqttAction,
        // site: state => state.site,
        // controller: state => state.controller,
        // weather: state => state.weather,
        nodeInstall: state => state.nodeInstall,
        nodesStatus: state => state.nodesStatus,
        mqttServer: state => state.mqttServer,
        mqttServerStatus: state => state.mqttServerStatus,
        searchBox: state => state.searchBox,
        qrCodeBox: state => state.qrCodeBox
    }),
    nodesOnline() {
      return this.$filter(this.nodesStatus, function(node) { return node.code === 1 }).length
    },
    nodesOffline() {
      return this.$filter(this.nodesStatus, function(node) { return node.code === -1 }).length
    },
    nodesTotal() {
      return this.nodesStatus.length
    },        
  },
  watch: {
      searchName() {
        if (this.searchName !== null) {
          console.log('this.searchName.toLowerCase() = ' + this.searchName.toLowerCase())
          this.filterNodes = this.nodesStatus.filter(node => node.name.toLowerCase().includes (this.searchName.toLowerCase()))
        } else {
          this.filterNodes = this.$sortBy(this.nodesStatus, 'code');        
        }
      },
      searchId() {
        if (this.searchId !== null) {
          this.filterNodes = this.nodesStatus.filter(node => node.id === this.searchId)
        } else {
          this.filterNodes = this.$sortBy(this.nodesStatus, 'code');        
        }
      },      
      selectValue() {
        if (this.selectValue !== 0) {
          var filtered = this.nodesStatus.filter(node => node.code === this.selectValue)
          this.filterNodes = this.$sortBy(filtered, 'code')
        } else {
          this.filterNodes = this.$sortBy(this.nodesStatus, 'code');
        }
      }      
    },
  methods: {
    
     // QR Decode
    onDecode (decodedString) {
      this.searchId = decodedString;
    },
        // Toggle Search Box
    toggleQRCodeBox() {
      console.log('toggleQRCodeBox  = ' + this.toggleQRCodeBox)
      if (this.qrCodeBox) {
        this.$store.commit('update_qrCodeBox',  false)
      } else {
        this.$store.commit('update_qrCodeBox',  true)
      }
    },
    returnNodeName(id){
        var nodeInstall = this.get_nodeInstall(id)[0];
        if(nodeInstall != undefined){
          return nodeInstall.name;
        }else{
          return undefined
        }
    }
  },
  mounted() {
    var t = this;
    setTimeout(function() {
      t.filterNodes = t.$sortBy(t.nodesStatus, 'code')
    }, 2000);
  }
}
</script>
